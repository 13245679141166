import React from 'react'
import Popover from 'react-popover';
import logo from '../img/salesforce-logo.svg'
import phone from '../img/phone.svg'
import headset from '../img/headset.svg'


const Navbar = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
    this._togglePopover = this._togglePopover.bind(this);
  }

  _togglePopover() {
    let {isOpen} = this.state;
    this.setState({isOpen: !isOpen});
  }

 render() {
   let {isOpen} = this.state;
   return (
     <div className="top-navbar">
       <div className="container">
         <div className="row align-items-center">
           <div className="col-6">
             <a href="https://www.salesforce.com/government" className="header-logo-link" id="logo" target="_blank" rel="noopener noreferrer">
               <img
                 className="logo"
                 alt="Salesforce"
                 src={logo}
               />
             </a>
           </div>
           <div className="col-6 text-right">
             <Popover
               className="contact-us-popover"
               body={
                 <div>
                   <div className="popover-body-row">
                     <a href="tel:1-844-807-8829" id="contact-phone"><img src={phone} alt="" className="img-fluid"/> 1-844-807-8829</a>
                   </div>
                   <div>
                     <a href="https://www.salesforce.com/form/contact/contactme-government.jsp?d=7010M000000j918QAA&ind=Public_Sector&ppc=Public%20Sector" id="contact-us" target="_blank" rel="noopener noreferrer"><img src={headset} alt="" className="img-fluid"/> Request a Call</a>
                   </div>
                 </div>
               }
               isOpen={isOpen}
               onOuterAction={this._togglePopover}
               place="below"
               tipSize={14}
             >
              <a onClick={this._togglePopover} id="contact-us" className="contact-us">Contact Us</a>
             </Popover>
           </div>
         </div>
       </div>
     </div>
  )}
}

export default Navbar
