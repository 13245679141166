import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import Helmet from "./Layout";

const Footer = class extends React.Component {
  constructor(props) {
    super(props);
  }

render() {

  return (
   <StaticQuery
     query={graphql`
        query footerQuery {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "footer" } }}
          ) {
            edges {
              node {
                frontmatter {
                  copy
                  links {
                    linkText
                    linkURL
                  }
                }
              }
            }
          }
        }
      `}
     render={data => (
       <footer className="footer">
         <div className="container">
           <div className="row align-items-center d-none d-sm-flex">
             <div className="col-12 col-sm-7 col-lg-8">
               <p><small dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.frontmatter.copy}}></small></p>
             </div>
             <div className="col-12 col-sm-5 col-lg-4">
               <ul>
                 {
                   data.allMarkdownRemark.edges[0].node.frontmatter.links.map((link, index) => (
                     <li key={`footer-links-${index}`}><a href={link.linkURL} target="_blank" id={link.linkText}>{link.linkText}</a></li>
                   ))
                 }
               </ul>
             </div>
           </div>
           <div className="row align-items-center d-sm-none">
             <div className="col-12">
               <ul>
                 {
                   data.allMarkdownRemark.edges[0].node.frontmatter.links.map((link, index) => (
                     <li key={`footer-links-${index}`}><a id={link.linkText} href={link.linkURL} target="_blank">{link.linkText}</a></li>
                   ))
                 }
               </ul>
             </div>
             <div className="col-12">
               <p><small dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.frontmatter.copy}}></small></p>
             </div>
           </div>
         </div>
       </footer>
     )}
   />
  )}
}

export default Footer
