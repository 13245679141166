import React, { Component } from "react";

class Privacy extends Component {

  render() {
    return (
      <div className="bg-altostratus privacy-bar container privacy-bar-container" id="privacy-bar">
        <div className="privacy-bar-content">
          <p>We use cookies to make interactions with our websites and services easy and meaningful, to better
            understand how they are used and to tailor advertising. You can read more and make your cookie choices&nbsp;
            <a href="http://www.salesforce.com/uk/company/privacy/full_privacy.jsp#nav_info" target="_blank" rel="noopener noreferrer">here</a>. By continuing to
            use this site you are giving us your consent to do this.</p>
          <div>
          </div>
        </div>
        <div id="privacy-bar-close">
            <span alt="Close button" className="icon-sfdc-icon-x privacy-bar-close-button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="close" width="100%" height="100%">
                <path d="M14.3 11.7l6-6c.3-.3.3-.7 0-1l-.9-1c-.3-.2-.7-.2-1 0l-6 6.1c-.2.2-.5.2-.7 0l-6-6.1c-.3-.3-.7-.3-1 0l-1 1c-.2.2-.2.7 0 .9l6.1 6.1c.2.2.2.4 0 .6l-6.1 6.1c-.3.3-.3.7 0 1l1 1c.2.2.7.2.9 0l6.1-6.1c.2-.2.4-.2.6 0l6.1 6.1c.2.2.7.2.9 0l1-1c.3-.3.3-.7 0-1l-6-6c-.2-.2-.2-.5 0-.7z"></path>
              </svg>
            </span>
        </div>
      </div>
    );
  }
}

export default Privacy