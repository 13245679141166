import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Privacy from "./Privacy";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { ContextProviderComponent } from "./Context";
import ContextConsumer from "./Context";
import "../scss/styles.scss";
import Script from "react-load-script";

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={headingQuery => (
      <ContextProviderComponent>
        <ContextConsumer>
          {({ data }) => (
            <div className={`layout ${data.bkgClass}`}>
              <div className="bkg-mobile" />
              <div className="bkg background" />
              <div className="bkg city-4a" />
              <div className="bkg city-4b" />
              <div className="bkg city-4c" />
              <div className="bkg city-4d" />
              <div className="bkg city-3a" />
              <div className="bkg city-3b" />
              <div className="bkg city-3c" />
              <div className="bkg city-2a" />
              <div className="bkg city-2b" />
              <div className="bkg city-2c" />
              <div className="bkg city-2d" />
              <div className="bkg city-2e" />
              <div className="bkg city-1a" />
              <div className="bkg city-1b" />
              <div className="bkg city-1c" />
              <div className="bkg foreground" />
              <Helmet>
                <html lang="en" />
                <title>{headingQuery.site.siteMetadata.title}</title>
                <meta
                  name="description"
                  content={headingQuery.site.siteMetadata.description}
                />

                <link rel="icon" type="image/png" href="/img/favicon.ico" />

                <link
                  rel="mask-icon"
                  href="/img/safari-pinned-tab.svg"
                  color="#ff4400"
                />
                <meta name="theme-color" content="#fff" />

                <meta property="og:type" content="business.business" />
                <meta
                  property="og:title"
                  content={headingQuery.site.siteMetadata.title}
                />
                <meta property="og:url" content="/" />
                <meta property="og:image" content="/img/og-image.jpg" />
              </Helmet>

              <Navbar />
              <div>{children}</div>
              <Footer />
              <Privacy />
              <Script url="/js/privacy.js" />
            </div>
          )}
        </ContextConsumer>
      </ContextProviderComponent>
    )}
  />
);

export default TemplateWrapper;
